import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';

const AFKGuidesCharms: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Charms - Season 2</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_arti_s.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Charms - Season 2</h1>
          <h2>List of Charms available in the Season 2 of AFK Journey.</h2>
          <p>
            Last updated: <strong>16/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Charms - Season 2" />
        <p>
          <strong>Charms in Season 2 were drastically buffed</strong> compared
          to Season 1 - that's mainly because the seasonal hero skills that were
          featured in Season 1 have been removed from the game. Find the list of
          Charms, their skills and which characters can use them below!
        </p>
        <SectionHeader title="List of Charms" />
        <p>
          Please keep in mind that the bonuses listed below{' '}
          <strong>show the values of the buffs at Mythic</strong> - so the
          highest rarity of them. At lower rarities, the value of the buffs and
          the buff itself will be weaker.
        </p>
        <div className="artifacts with-charms">
          <div className="column">
            <h5>Charm #1</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Increases a target's damage taken by <b>16%</b> for <b>6s</b>.
                And grants <b>30%</b> ATK boost for <b>6s</b> when inflicting
                control effects on that target. Unstackable.
              </p>
              <p className="sub">Type:</p>
              <p>
                Control Support / Debuff (Enemy DMG taken) / Self Buff (ATK)
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="carolina"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="arden"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #2</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Restores <b>30</b> energy per second in battle. Grants{' '}
                <b>25%</b> damage bonus and increases Energy recovered per
                second to <b>40</b> for <b>8s</b> after casting ultimate.
              </p>
              <p className="sub">Type:</p>
              <p>Self Buff (DMG/ER)</p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="alsa"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="cassadee"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="parisa"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="satrana"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="mirael"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #3</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Grants <b>16%</b> DMG reduction and <b>16</b> energy on hit for{' '}
                <b>45s</b> to the ally placed 1 tile in front of the hero when
                the battle starts. The hero also gains a <b>350%</b> ATK% shield
                for the rest of the battle.
              </p>
              <p className="sub">Type:</p>
              <p>Buff Ally (DMG taken reduction/ER/Shield)</p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="hewynn"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="smokey-and-meerky"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="damian"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="koko"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #4</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Increases a target's damage taken by <b>12%</b> for <b>6s</b>{' '}
                after the hero deals damage to the target. Unstackable. At{' '}
                <b>20s and 50s</b> into a battle, the damage taken increase
                rises to <b>15%</b> and <b>18%</b>, respectively.
              </p>
              <p className="sub">Type:</p>
              <p>Debuff (Enemy DMG taken)</p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="lily-may"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="thoran"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="ulmus"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #5</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Dispels most debuffs on the hero and grants them <b>80</b> Haste
                for <b>6s</b> when their HP drops below 35% for the first time.
                Grants the hero an extra <b>60</b> Life Drain while this effect
                is active.
              </p>
              <p className="sub">Type:</p>
              <p>Self Cleanse/Self Buff (Haste/Life Drain) </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="eironn"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="vala"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="silvina"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="soren"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #6</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Increases Phys and Magic DEF by <b>24%</b> for each enemy within{' '}
                <b>2</b> tiles. Also increases Vitality by <b>35</b>, plus an
                extra <b>5</b> vitality for each enemy within <b>2</b> tiles.
              </p>
              <p className="sub">Type:</p>
              <p>Self Buff (P.DEF/M.DEF/Vitality)</p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="granny-dahnie"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="lumont"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="lucius"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #7</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Increases all damage except normal attack damage by <b>40%</b>{' '}
                for <b>60s</b>. Grants the hero <b>35</b> crit for all
                non-normal attacks.
              </p>
              <p className="sub">Type:</p>
              <p>
                Self Buff (non-normal atk DMG) / Self Buff (non-normal atk CRIT)
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="talene"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="viperian"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #8</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Increases Crit by <b>100</b> for the next <b>3</b> hits and
                restores <b>140%</b> HP per second over the next <b>6s</b> after
                the hero assists or defeats an enemy, excluding summons. Grants
                the hero control immunity and a<b>20%</b> ATK boost while this
                effect is active.
              </p>
              <p className="sub">Type:</p>
              <p>
                Self Buff (CRIT/ATK) / Self Heal (on kill/assist) / Control
                Immunity
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="shakir"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="lenya"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="seth"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #9</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Increases damage by <b>1.5%</b> and Phys & Magic DEF by{' '}
                <b>5%</b> for every <b>3s</b> the hero remains in the battle.
                Increases damage by <b>9%</b> and Phys & Magic DEF by <b>30%</b>{' '}
                when assisting or defeating a non-summoned enemy. The total
                increase from both effects is capped at <b>18%</b> for damage
                and <b>60%</b> for Phys & Magic DEF. When the limit is reached
                grants the hero an extra <b>25</b> Haste for the rest of the
                battle.
              </p>
              <p className="sub">Type:</p>
              <p>Self Buff (DMG/P.DEF/M.DEF/Haste)</p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="scarlita"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="florabelle"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="odie"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="kruger"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="korin"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #10</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Grants <b>30%</b> ATK boost for <b>45s</b> to the ally placed 1
                tile behind the hero when battle starts. When this ally assist
                or defeats an enemy other than a summon, their ATK is increased
                by an extra <b>6%</b>, and the duration of the buff is extended
                by 10s.
              </p>
              <p className="sub">Type:</p>
              <p>Buff Ally (ATK)</p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="reinier"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="ludovic"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="niru"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #11</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Dispels most debuffs and makes the hero untargetable for{' '}
                <b>3s</b> when HP drops below <b>35%</b> for the first time.
                Grants the hero a <b>550%</b> shield when the effect ends. The
                shield lasts for <b>6s</b>, during which the hero's Life Drain
                is also increased by <b>20</b>.
              </p>
              <p className="sub">Type:</p>
              <p>Self Cleanse / Self Buff (Shield/Life Drain)</p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="dionel"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #12</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Reduces damage taken by <b>25%</b> and increases their energy on
                hit by <b>30</b> in the first <b>15s</b> of battle.
              </p>
              <p className="sub">Type:</p>
              <p>Self Buff (DMG taken reduction/ER)</p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="phraesto"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="antandra"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #13</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Restores <b>60</b> energy per second until the hero reaches full
                Energy for the first time. Upon reaching full Energy for the
                first time, dispels control effects, grants control immunity for
                <b>6s</b>, and <b>25%</b> ATK for <b>6s</b>.
              </p>
              <p className="sub">Type:</p>
              <p>Self Buff (ER/ATK) / Self Cleanse / Control Immunity </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="mikola"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="rowan"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="fay"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #14</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Deals an extra <b>240%</b> damage when the hero hits a target.
                This effect can be triggered once per enemy every <b>6s</b>.
                This damage is increased to <b>360%</b> for targets with HP
                below <b>35%</b>.
              </p>
              <p className="sub">Type:</p>
              <p>Self Buff (Deal Extra Damage on Hit)</p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="berial"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="temesia"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="atalanta"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="marilee"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="walker"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="salazer"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #15</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Increases ATK by <b>30%</b> for <b>8s</b> when the hero is
                displaced by their own skills. Deals <b>280%</b> damage to
                adjacent enemies when displacement ends.
              </p>
              <p className="sub">Type:</p>
              <p>After Moving &gt; Self Buff (ATK) / Damage Enemy</p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="igor"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="valen"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="kafra"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #16</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Increases damage dealt by <b>20%</b> and grants <b>25</b> energy
                when attacking an enemy for each stat debuff inflicted on the
                enemy by different caster.
              </p>
              <p className="sub">Type:</p>
              <p>Debuff Support / Self Buff (DMG/ER)</p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="cecia"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="lyca"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #17</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Emits a shockwave every <b>5s</b>, and reduces Haste by{' '}
                <b>30</b> for the enemies within 1 tile for <b>5s</b>. Gains{' '}
                <b>15</b> energy for each enemy affected by this effect.
              </p>
              <p className="sub">Type:</p>
              <p>Debuff (Haste) / Self Buff (ER) / AOE Damage</p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="brutus"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #18</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Increases a target's damage taken by <b>25%</b> for <b>8s</b>{' '}
                and reduces target's Vitality for <b>80</b> after dealing damage
                to that target <b>2</b> times. This effect can be triggered once
                every <b>10s</b>.
              </p>
              <p className="sub">Type:</p>
              <p>Debuff (Enemy DMG taken/Vitality)</p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="rhys"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="bryon"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Unknown Charm</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                The Charm set effect for this characters is currently unknown.
              </p>
              <p className="sub">Type:</p>
              <p>-</p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="nara"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="dunlingr"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="sinbad"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesCharms;

export const Head: React.FC = () => (
  <Seo
    title="Charms - Season 2 | AFK Journey | Prydwen Institute"
    description="List of Charms available in the Season 2 of AFK Journey."
    game="afk"
  />
);
